import { combineReducers, configureStore } from '@reduxjs/toolkit';

const errorReducer = (state = { authError: undefined }, action) => {
  switch (action.type) {
    case 'SET_ERROR':
      const temp = {};
      const { payload } = action;

      if (payload.status && payload.status.length) {
        payload.status &&
          payload?.status.forEach((ele) => {
            if (ele.param === '_error') {
              ele.nestedErrors?.forEach((ele1) => {
                const key = ele1.param.split('.').pop();
                temp[key] = ele1.msg;
              });
            } else if (ele.param?.includes('updates.')) {
              const key = ele.param.split('.').slice(1).join('.');
              temp[key] = ele.msg;
            } else {
              temp[ele.path] = ele.msg;
            }
          });
      }

      return { ...state, [`${payload.scope}Error`]: temp };

    default:
      return state;
  }
};

const loadingReducer = (
  state = {
    authLoading: false,
    clientLoading: false,
    staffLoading: false,
    archivedStaffLoading: false,
    settingLoading: false,
    pricesLoading: false,
    archivePricesLoading: false,
    payGroupLoading: false,
    updatePayGroupLoading: false,
    allowanceLoading: false,
    shiftLoading: false,
    clientBillingReportLoading: false,
    updateShiftLogLoading: false,
    companyDocumentLoading: false,
    getCompanyDocumentLoading: false,
    staffDocLoading: false,
    clientDocumentLoading: false,
    clientExpiredDocumentLoading: false,
    shiftDashboardLoading: false,
    taskManageListLoading: false,
    archiveClientLoading: false,
    settingLoading: false,
    staffDocLoading: false,
    connectedCustomerLoading: false,
  },
  action
) => {
  switch (action.type) {
    case 'SET_LOADING':
      return Object.assign({}, state, {
        [`${action.payload.scope}Loading`]: action.payload.status,
      });
    default:
      return state;
  }
};

const msgReducer = (state = { success: undefined, error: undefined }, action) => {
  switch (action.type) {
    case 'SET_TOAST':
      return Object.assign({}, state, {
        [`${action.payload.scope}`]: action.payload.status || 'Something went wrong!',
      });
    case 'CLEAR_TOAST':
      return { ...state, error: undefined, success: undefined };
    default:
      return state;
  }
};

const authReducer = (
  state = {
    isLoggedIn: false,
    role: -1,
    user: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'AUTH_LOAD_SUCCESS':
      return {
        ...state,
        role: action?.payload?.role,
        isLoggedIn: true,
        user: action?.payload,
      };
    case 'AUTH_LOAD_FAILURE':
      return {
        ...state,
        isLoggedIn: false,
        role: -1,
      };
    case 'AUTH_LOGIN_SUCCESS':
      return {
        ...state,
        role: action.payload.role,
        isLoggedIn: true,
      };
    case 'AUTH_LOGOUT_SUCCESS':
      return {
        isLoggedIn: false,
        user: undefined,
        role: -1,
      };
    case 'LOGIN_SUCCESS':
      return {
        isLoggedIn: true,
        user: action.payload,
      };
    case 'LOGOUT_SUCCESS':
      return {
        isLoggedIn: false,
        role: -1,
      };

    default:
      return state;
  }
};

const clientReducer = (
  state = {
    clientList: undefined,
    clientCount: 0,
    archivedCount: 0,
    archivedClient: undefined,
    billingReport: undefined,
    clientDetails: undefined,
    fundList: undefined,
    fundListCount: 0,
    balance: 0,
    clientBillingReport: undefined,
    clientDocument: undefined,
    clientCategory: undefined,
    clientExpiredDocument: undefined,
    connectedCustomer: undefined,
    clientCalenderData: undefined,
    billingReportPerSlot: undefined,
    clientCommunicationList: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'CLIENT_COMMUNICATION_LIST_SUCCESS':
      return {
        ...state,
        clientCommunicationList: action.payload,
      };
    case 'CLIENT_LIST_SUCCESS':
    case 'CLIENT_SEARCH_SUCCESS':
      return {
        ...state,
        clientList: action.payload.list,
        clientCount: action.payload.count,
      };
    case 'CLIENT_SEARCH_CLEAR':
      return {
        ...state,
        clientList: undefined,
        clientCount: 0,
      };
    case 'ARCHIVED_LIST_SUCCESS':
    case 'ARCHIVED_SEARCH_SUCCESS':
      return {
        ...state,
        archivedClient: action.payload.list,
        archivedCount: action.payload.count,
      };
    case 'ARCHIVED_CLIENT_SEARCH_CLEAR':
      return {
        ...state,
        archivedClient: undefined,
        archivedCount: 0,
      };
    case 'CLIENT_BILLING_REPORT_SUCCESS':
      return {
        ...state,
        billingReport: action.payload,
      };
    case 'CLIENT_DETAILS_SUCCESS':
      return {
        ...state,
        clientDetails: action.payload,
      };
    case 'GET_FUND_SUCCESS':
      return {
        ...state,
        fundList: action.payload.list,
        fundListCount: action.payload.count,
        balance: action.payload.balance,
      };
    case 'GET_CLIENT_BILLING_REPORT':
      return {
        ...state,
        clientBillingReport: action.payload,
      };
    case 'GET_CLIENT_DOCUMENT':
      return {
        ...state,
        clientDocument: action.payload,
      };
    case 'GET_CLIENT_DOCUMENT_CATEGORY':
      return {
        ...state,
        clientCategory: action.payload,
      };
    case 'GET_CLIENT_EXPIRED_DOCUMENT':
      return {
        ...state,
        clientExpiredDocument: action.payload,
      };
    case 'GET_CLIENT_CONNECTED_CUSTOMERS':
      return {
        ...state,
        connectedCustomer: action.payload,
      };
    case 'GET_CLIENT_SHIFT_TIMELINER':
      return {
        ...state,
        clientCalenderData: action.payload,
      };
    case 'CLEAR_CLIENT_DETAILS':
      return {
        ...state,
        clientDetails: undefined,
      };
    case 'GET_SLOT_BILLING_REPORT':
      return {
        ...state,
        billingReportPerSlot: action.payload,
      };
    case 'CLEAR_SLOT_BILLING_REPORT':
      return {
        ...state,
        billingReportPerSlot: undefined,
      };
    default:
      return state;
  }
};

const staffReducer = (
  state = {
    staffList: undefined,
    staffCount: 0,
    archivedStaffCount: 0,
    archivedStaff: undefined,
    staffDetails: undefined,
    staffTimeSheet: undefined,
    staffTimeSheetPageInfo: undefined,
    staffTimeSheetCount: undefined,
    communicationList: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'STAFF_LIST_SUCCESS':
    case 'STAFF_SEARCH_SUCCESS':
      return {
        ...state,
        staffList: action.payload.list,
        staffCount: action.payload.count,
      };
    case 'STAFF_SEARCH_CLEAR':
      return {
        ...state,
        staffList: undefined,
        staffCount: 0,
      };
    case 'ARCHIVED_STAFF_LIST_SUCCESS':
    case 'ARCHIVED_STAFF_SEARCH_SUCCESS':
      return {
        ...state,
        archivedStaff: action.payload.list,
        archivedCount: action.payload.count,
      };
    case 'ARCHIVED_STAFF_SEARCH_CLEAR':
      return {
        ...state,
        archivedStaff: undefined,
        archivedStaffCount: 0,
      };
    case 'STAFF_DETAILS_SUCCESS':
      return {
        ...state,
        staffDetails: action.payload,
      };
    case 'GET_STAFF_TIMESHEET':
      return {
        ...state,
        staffTimeSheet: action.payload,
      };
    case 'GET_STAFF_TIMESHEET_COUNT':
      return {
        ...state,
        staffTimeSheetCount: action.payload,
      };
    case 'GET_STAFF_TIMESHEET_PAGE_INFO':
      return {
        ...state,
        staffTimeSheetPageInfo: action.payload,
      };
    case 'CLEAR_STAFF_DETAILS':
      return {
        ...state,
        staffDetails: undefined,
      };
    case 'STAFF_COMMUNICATION_LIST_SUCCESS':
      return {
        ...state,
        communicationList: action.payload,
      };
    default:
      return state;
  }
};

const teamReducer = (
  state = {
    teamList: undefined,
    count: 0,
    teamDetails: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'TEAM_LIST_SUCCESS':
    case 'TEAM_SEARCH_SUCCESS':
      return {
        ...state,
        teamList: action.payload.list,
        count: action.payload.count,
      };
    case 'TEAM_SEARCH_CLEAR':
      return {
        ...state,
        teamList: undefined,
        count: 0,
      };
    case 'GET_TEAM_BY_ID':
      return {
        ...state,
        teamDetails: action.payload,
      };
    case 'CLEAR_TEAM_DETAILS':
      return {
        ...state,
        teamDetails: undefined,
      };
    default:
      return state;
  }
};

const userDocReducer = (
  state = {
    expiredDoc: undefined,
    expiredDocCount: 0,
  },
  action
) => {
  switch (action.type) {
    case 'USER_EXPIRED_DOC_SUCCESS':
      return {
        ...state,
        expiredDoc: action.payload.list,
        expiredDocCount: action.payload.count,
      };
    default:
      return state;
  }
};

const staffDocReducer = (
  state = {
    staffDocument: undefined,
    expiredDoc: undefined,
    expiredDocCount: 0,
  },
  action
) => {
  switch (action.type) {
    case 'STAFF_EXPIRED_DOC_SUCCESS':
      return {
        ...state,
        expiredDoc: action.payload.list,
        expiredDocCount: action.payload.count,
      };
    case 'GET_STAFF_DOCUMENT':
      return {
        ...state,
        staffDocument: action.payload,
      };
    default:
      return state;
  }
};

const invoiceReducer = (
  state = {
    invoiceDetails: undefined,
    adminInvoiceList: undefined,
    adminInvoicePageInfo: undefined,
    adminInvoiceDashboard: undefined,
    adminCreateInvoiceList: undefined,
    adminCompleteInvoice: undefined,
    adminCompleteInvoiceHistory: undefined,
    clientInvoiceList: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'GET_INVOICE_SETTINGS_DETAILS':
      return {
        ...state,
        invoiceDetails: action.payload,
      };
    case 'GET_ADMIN_INVOICE_LIST':
      return {
        ...state,
        adminInvoiceList: action.payload,
      };
    case 'GET_ADMIN_INVOICE_PAGE_INFO':
      return {
        ...state,
        adminInvoicePageInfo: action.payload,
      };
    case 'GET_ADMIN_INVOICE_DASHBOARD':
      return {
        ...state,
        adminInvoiceDashboard: action.payload,
      };
    case 'GET_ADMIN_CREATE_INVOICE':
      return {
        ...state,
        adminCreateInvoiceList: action.payload,
      };
    case 'GET_ADMIN_INVOICE_HISTORY':
      return {
        ...state,
        adminCompleteInvoiceHistory: action.payload,
      };
    case 'GET_ADMIN_COMPLETE_INVOICE':
      return {
        ...state,
        adminCompleteInvoice: action.payload,
      };
    case 'GET_CLIENT_INVOICE_LIST':
      return {
        ...state,
        clientInvoiceList: action.payload,
      };
    default:
      return state;
  }
};

const goalReducer = (state = { goalList: undefined, goalCount: 0, goalDetails: undefined }, action) => {
  switch (action.type) {
    case 'GOAL_LIST_SUCCESS':
      return {
        ...state,
        goalList: action.payload.list,
        goalCount: action.payload.count,
      };
    case 'GOAL_DETAILS_SUCCESS':
      return {
        ...state,
        goalDetails: action.payload,
      };
    case 'GOAL_DETAILS_CLEAR':
      return {
        ...state,
        goalDetails: undefined,
      };
    default:
      return state;
  }
};

const priceBookReducer = (
  state = {
    priceBookList: undefined,
    priceBookDetails: undefined,
    archivedPriceBook: undefined,
    priceBookCount: 0,
  },
  action
) => {
  switch (action.type) {
    case 'PRICE_BOOK_LIST_SUCCESS':
      return {
        ...state,
        priceBookList: action.payload.list,
        priceBookCount: action.payload.count,
      };
    case 'PRICE_BOOK_DETAILS_SUCCESS':
      return {
        ...state,
        priceBookDetails: action.payload,
      };
    case 'ARCHIVED_PRICE_BOOK_LIST_SUCCESS':
      return {
        ...state,
        archivedPriceBook: action.payload,
      };
    default:
      return state;
  }
};

const payGroupReducer = (
  state = {
    payGroupList: undefined,
    payGroupDetails: undefined,
    archivedPayGroup: undefined,
    xeroPayItem: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'PAY_GROUP_LIST_SUCCESS':
      return {
        ...state,
        payGroupList: action.payload,
      };
    case 'PAY_GROUP_DETAILS_SUCCESS':
      return {
        ...state,
        payGroupDetails: action.payload,
      };
    case 'ARCHIVED_PAY_GROUP_LIST_SUCCESS':
      return {
        ...state,
        archivedPayGroup: action.payload,
      };
    case 'XERO_PAY_ITEM_LIST_SUCCESS':
      return {
        ...state,
        xeroPayItem: action.payload,
      };
    default:
      return state;
  }
};

const settingReducer = (
  state = {
    settingsList: undefined,
    // companyDetails: undefined,
    // notesPermission: undefined,
    templateDetails: undefined,
    moodDetails: undefined,
    moodLevelDetails: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'SETTING_LIST_SUCCESS':
      return {
        ...state,
        settingsList: action.payload,
      };
    // case "FETCH_COMPANY_DETAILS_SUCCESS":
    //   return {
    //     companyDetails: action.payload,
    //   };
    // case "FETCH_NOTES_PERMISSION_SUCCESS":
    //   return {
    //     notesPermission: action.payload,
    //   };
    case 'GET_TEMPLATE_BY_ID':
      return {
        ...state,
        templateDetails: action.payload,
      };
    case 'GET_MOOD_BY_ID':
      return {
        ...state,
        moodDetails: action.payload,
      };
    case 'GET_MOOD_LEVEL_BY_ID':
      return {
        ...state,
        moodLevelDetails: action.payload,
      };
    default:
      return state;
  }
};

const allowanceReducer = (
  state = {
    allowanceList: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'ALLOWANCE_LIST_SUCCESS':
      return {
        ...state,
        allowanceList: action.payload,
      };
    default:
      return state;
  }
};

const taskReducer = (
  state = {
    clientTaskList: undefined,
    taskList: undefined,
    taskCount: 0,
    shiftTaskData: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'CLIENT_TASK_LIST_SUCCESS':
      return {
        ...state,
        clientTaskList: action.payload,
      };
    case 'TASK_LIST_SUCCESS':
      return {
        ...state,
        taskList: action.payload.list,
        taskCount: action.payload.count,
      };
    case 'CLIENT_SHIFT_TASK':
      return {
        ...state,
        shiftTaskData: action.payload,
      };
    default:
      return state;
  }
};

const dashboardReducer = (
  state = {
    dashboardCount: undefined,
    dashboardClient: undefined,
    dashboardStaff: undefined,
    dashboardInvoice: undefined,
    clientDashboard: undefined,
    manageProcess: undefined,
    manageProcessPageInfo: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'DASHBOARD_COUNT_SUCCESS':
      return {
        ...state,
        dashboardCount: action.payload,
      };
    case 'DASHBOARD_CLIENT_SUCCESS':
      return {
        ...state,
        dashboardClient: action.payload,
      };
    case 'DASHBOARD_STAFF_SUCCESS':
      return {
        ...state,
        dashboardStaff: action.payload,
      };
    case 'DASHBOARD_INVOICE_SUCCESS':
      return {
        ...state,
        dashboardInvoice: action.payload,
      };
    case 'GET_CLIENT_DASHBOARD':
      return {
        ...state,
        clientDashboard: action.payload,
      };
    case 'GET_MANAGE_PROCESS':
      return {
        ...state,
        manageProcess: action.payload,
      };
    case 'GET_MANAGE_PROCESS_PAGE_INFO':
      return {
        ...state,
        manageProcessPageInfo: action.payload,
      };

    default:
      return state;
  }
};

const shiftReducer = (
  state = {
    clientFund: undefined,
    clientLocation: undefined,
    clientTask: undefined,
    shiftList: undefined,
    shiftDetails: undefined,
    shiftDashboard: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'CLIENT_FUND_SUCCESS':
      return {
        ...state,
        clientFund: action.payload,
      };
    case 'CLIENT_LOCATION_SUCCESS':
      return {
        ...state,
        clientLocation: action.payload,
      };
    case 'CLIENT_TASK_SUCCESS':
      return {
        ...state,
        clientTask: action.payload,
      };
    case 'SHIFT_LIST_SUCCESS':
      return {
        ...state,
        shiftList: action.payload,
      };
    case 'SHIFT_DETAILS_SUCCESS':
      return {
        ...state,
        shiftDetails: action.payload,
      };
    case 'GET_SHIFT_TIMELINER':
      return {
        ...state,
        shiftDashboard: action.payload,
      };
    case 'GET_SHIFT_TIMELINER_APPEND':
      return {
        ...state,
        shiftDashboard: { ...state.shiftDashboard, data: [...state.shiftDashboard.data, ...action.payload] },
      };
    case 'DELETE_SHIFT':
      const { userIds, shiftId } = action.payload;

      const updatedData = state.shiftDashboard.data.map((user) => {
        if (userIds.includes(user._id)) {
          const updatedShifts = user.shifts.filter((shift) => {
            return shift._id.shiftId !== shiftId;
          });

          return {
            ...user,
            shifts: updatedShifts,
          };
        }

        return user;
      });

      return {
        ...state,
        shiftDashboard: {
          ...state.shiftDashboard,
          data: updatedData,
        },
      };

    // case 'ADD_SHIFT':
    //   const { membersId, shiftData } = action.payload;

    //   const updatedShiftDashboardData = state.shiftDashboard.data.map((member) => {
    //     if (membersId.includes(member._id)) {
    //       return {
    //         ...member,
    //         shifts: [...member.shifts, shiftData],
    //       };
    //     }
    //     return member;
    //   });

    //   return {
    //     ...state,
    //     shiftDashboard: {
    //       ...state.shiftDashboard,
    //       data: updatedShiftDashboardData,
    //     },
    //   };

    // case 'UPDATE_SHIFTS':
    //   return {
    //     ...state,
    //     shiftDashboard: {
    //       ...state.shiftDashboard,
    //       data: state.shiftDashboard.data.map((member) => {
    //         const updatedMember = action.payload.find((updated) => updated._id === member._id);

    //         return updatedMember ? { ...member, ...updatedMember } : member;
    //       }),
    //     },
    //   };

    case 'UPDATE_SHIFTS':
      return {
        ...state,
        shiftDashboard: {
          ...state.shiftDashboard,
          data: state.shiftDashboard.data.map((member) => {
            // Find the updated member in the payload
            const updatedMember = action.payload.find((updated) => updated._id === member._id);

            if (updatedMember) {
              // Merge existing shifts with the new shifts from the payload
              const updatedShifts = [
                ...member.shifts, // existing shifts
                ...updatedMember.shifts, // new shifts from the payload
              ];

              return {
                ...member,
                ...updatedMember, // Update other member fields
                shifts: updatedShifts, // Update the shifts array
              };
            }

            // If the member is not found in the payload, return the original member
            return member;
          }),
        },
      };
    case 'RESET_SHIFT_DASHBOARD_DATA':
      return {
        ...state,
        shiftDashboard: undefined,
      };
    default:
      return state;
  }
};

const reportReducer = (
  state = {
    timeSheetList: undefined,
    timeSheetPageInfo: undefined,
    incident: undefined,
    overall: undefined,
    logistic: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'GET_REPORT_TIME_SHEET':
      return {
        ...state,
        timeSheetList: action.payload,
      };

    case 'GET_REPORT_TIME_SHEET_PAGE_INFO':
      return {
        ...state,
        timeSheetPageInfo: action.payload,
      };

    case 'GET_REPORT_INCIDENT':
      return {
        ...state,
        incident: action.payload,
      };
    case 'GET_REPORT_OVERALL':
      return {
        ...state,
        overall: action.payload,
      };

    case 'GET_REPORT_LOGISTIC':
      return {
        ...state,
        logistic: action.payload,
      };

    default:
      return state;
  }
};

const companyDocumentReducer = (
  state = {
    companyDocumentList: undefined,
    companyDocumentPageInfo: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'GET_COMPANY_DOCUMENT':
      return {
        ...state,
        companyDocumentList: action.payload,
      };
    case 'GET_COMPANY_DOCUMENT_PAGE_INFO':
      return {
        ...state,
        companyDocumentPageInfo: action.payload,
      };

    default:
      return state;
  }
};

const shiftDeleteReducer = (
  state = {
    isDeleteMode: false,
    shiftsToRemove: [],
  },
  action
) => {
  switch (action.type) {
    case 'TURN_ON_DELETE_MODE':
      return {
        ...state,
        isDeleteMode: true,
      };
    case 'TURN_OFF_DELETE_MODE':
      return {
        ...state,
        isDeleteMode: false,
      };
    case 'ADD_SHIFT_TO_REMOVE':
      return {
        ...state,
        shiftsToRemove: [...new Set([...state.shiftsToRemove, action.payload])],
      };

    case 'REMOVE_SHIFT_FROM_REMOVE':
      return {
        ...state,
        shiftsToRemove: state.shiftsToRemove.filter((shift) => shift.shiftId !== action.payload),
      };

    case 'RESET_DELETE_REDUCER':
      return {
        ...state,
        shiftsToRemove: [],
      };

    default:
      return state;
  }
};

// Client Role

const clientShiftReducer = (
  state = {
    shiftList: undefined,
    shiftDetail: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'GET_CLIENT_SHIFT_LIST':
      return {
        ...state,
        shiftList: action.payload,
      };
    case 'GET_CLIENT_SHIFT_DETAILS':
      return {
        ...state,
        shiftDetail: action.payload,
      };
    default:
      return state;
  }
};

const clientDetailsReducer = (
  state = {
    clientData: undefined,
    documentData: undefined,
    goalData: undefined,
    goalDetail: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_CLIENT_DETAILS':
      return {
        ...state,
        clientData: action.payload,
      };
    case 'FETCH_CLIENT_DOCUMENT':
      return {
        ...state,
        documentData: action.payload,
      };
    case 'FETCH_CLIENT_GOAL':
      return {
        ...state,
        goalData: action.payload,
      };

    case 'FETCH_GOAL_BY_ID':
      return {
        ...state,
        goalDetail: action.payload,
      };

    case 'CLEAR_ALL_GOAL_DETAIL':
      return {
        ...state,
        goalDetail: undefined,
        goalData: undefined,
      };

    case 'CLEAR_GOAL_DETAIL':
      return {
        ...state,
        goalDetail: undefined,
      };

    default:
      return state;
  }
};

const staticPageReducer = (
  state = {
    singleStaticPage: undefined,
    loading: false,
    error: false,
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_STATIC_PAGE_BY_URL_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
    case 'SINGLE_STATIC_PAGE_SUCCESS':
      return {
        ...state,
        singleStaticPage: action.payload,
        loading: false,
        error: false,
      };

    case 'STATIC_PAGE_NOT_FOUND':
      return {
        ...state,
        singleStaticPage: undefined,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

const notificationReducer = (
  state = {
    notificationList: undefined,
    unreadCount: 0,
  },

  action
) => {
  switch (action.type) {
    case 'FETCH_NOTIFICATION_LIST':
      return {
        ...state,
        notificationList: action.payload,
      };
    case 'FETCH_NOTIFICATION_COUNT':
      return {
        ...state,
        unreadCount: action.payload,
      };
    default:
      return state;
  }
};

const shiftDetailsModalReducer = (
  state = {
    isModalOpen: false,
  },

  action
) => {
  switch (action.type) {
    case 'OPEN_SHIFT_DETAILS_MODAL':
      return {
        ...state,
        isModalOpen: true,
      };
    case 'CLOSE_SHIFT_DETAILS_MODAL':
      return {
        ...state,
        isModalOpen: false,
      };
    default:
      return state;
  }
};

/* ------------------------------------------------------------------------------------------------- Mobile Reducers Start --------------------------------------------------------------------------------------- */

const mobileDashboardReducer = (
  state = {
    shifts: [],
  },
  action
) => {
  switch (action.type) {
    case 'MOBILE_DASHBOARD_SHIFT_SUCCESS':
      return {
        ...state,
        shifts: [...state.shifts, ...action.payload],
      };
    case 'RESET_SHIFT_DATA':
      return {
        ...state,
        shifts: [],
      };

    default:
      return state;
  }
};

const mobileNotificationReducer = (
  state = {
    notifications: [],
    unreadCount: 0,
  },

  action
) => {
  switch (action.type) {
    case 'FETCH_NOTIFICATION_LIST_MOBILE':
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload],
      };
    case 'FETCH_NOTIFICATION_COUNT_MOBILE':
      return {
        ...state,
        unreadCount: action.payload,
      };
    default:
      return state;
  }
};

const mobileShiftReducer = (
  state = {
    shift: undefined,
    slots: undefined,
    clients: undefined,
    tasks: undefined,
    taskCount: { completed: undefined, total: undefined },
    isTimeLogged: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'MOBILE_SHIFT_DETAILS_SUCCESS':
      return {
        ...state,
        shift: action.payload.shift[0],
        slots: action.payload.shift[0].slots,
        clients: action.payload.shift[0].clients,
        taskCount: {
          ...state.taskCount,
          completed: action.payload.taskscounts[0] ? action.payload.taskscounts[0].totalCompleteTasks : 0,
          total: action.payload.taskscounts[0] ? action.payload.taskscounts[0].totalTasks : 0,
        },
        isTimeLogged: action.payload.shift[0].clients.every((client) => client.logs && client.logs.length > 0),
      };

    case 'RESET_SHIFT':
      return {
        ...state,
        shift: undefined,
      };

    case 'FETCH_MOBILE_SHIFT_TASKS_SUCCESS':
      return {
        ...state,
        tasks: action.payload,
      };

    case 'UPDATE_TASK_COUNT':
      if (action.payload === 'check') {
        return {
          ...state,
          taskCount: {
            ...state.taskCount,
            completed: state.taskCount.completed + 1,
          },
        };
      } else if (action.payload === 'uncheck') {
        return {
          ...state,
          taskCount: {
            ...state.taskCount,
            completed: state.taskCount.completed - 1,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case 'ADD_TASK':
      return {
        ...state,
        taskCount: {
          ...state.taskCount,
          total: state.taskCount.total + 1,
        },
      };

    case 'TOGGLE_TASK':
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (action.payload === task._id) {
            return { ...task, isComplete: !task.isComplete };
          }
          return task;
        }),
      };

    default:
      return state;
  }
};

const unavailabilityReducer = (
  state = {
    dates: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'MOBILE_UNAVAILABILITY_SUCCESS':
      return {
        ...state,
        dates: action.payload.map((date) => date.unavailableDate),
      };

    default:
      return state;
  }
};

const progressReducer = (
  state = {
    progressQuestionsData: undefined,
    defaultQuestionsData: undefined,
    progressList: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_SHIFT_PROGRESS_LIST':
      return {
        ...state,
        progressList: action.payload,
      };
    case 'FETCH_PROGRESS_QUESTIONS':
      return {
        ...state,
        progressQuestionsData: action.payload,
      };

    case 'FETCH_DEFAULT_PROGRESS_QUESTIONS_DATA':
      return {
        ...state,
        defaultQuestionsData: action.payload,
      };

    case 'EMPTY_PROGRESS_DATA':
      return {
        ...state,
        progressQuestionsData: undefined,
        defaultQuestionsData: undefined,
      };
    default:
      return state;
  }
};

const timelineReducer = (
  state = {
    allowanceData: undefined,
    taskData: undefined,
    moodData: undefined,
    notesData: undefined,
    selectedMood: [],
    locationData: undefined,
    progressNoteData: undefined,
    questionsData: undefined,
    shiftNotesClients: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'CLEAR_SHIFT_NOTES_CLIENTS':
      return {
        ...state,
        shiftNotesClients: undefined,
      };
    case 'FETCH_PROGRESS_QUESTIONS_STATUS':
      return {
        ...state,
        shiftNotesClients: action.payload,
      };
    case 'FETCH_PROGRESS_QUESTIONS':
      return {
        ...state,
        questionsData: action.payload,
      };
    case 'FETCH_PROGRESSNOTE':
      return {
        ...state,
        progressNoteData: action.payload,
      };

    case 'DELETE_PROGRESS_NOTE':
      const { clientId, pageStatus } = action.payload;

      const updatedUsers = state.allowanceData.users.map((user) => {
        if (user._id === clientId) {
          if (user.progressnotes[pageStatus]) {
            const updatedProgressnotes = { ...user.progressnotes };
            delete updatedProgressnotes[pageStatus];

            return {
              ...user,
              progressnotes: updatedProgressnotes,
            };
          }
        }
        return user;
      });

      return {
        ...state,
        allowanceData: {
          ...state.allowanceData,
          users: updatedUsers,
        },
      };
    case 'FETCH_ALLOWANCE':
      return {
        ...state,
        allowanceData: action.payload,
      };

    case 'FETCH_ALL_TASKS':
      return {
        ...state,
        taskData: action.payload,
      };

    case 'FETCH_ALL_MOOD':
      return {
        ...state,
        moodData: action.payload,
      };

    case 'FETCH_NOTES_DATA':
      return {
        ...state,
        notesData: action.payload,
      };

    case 'ADD_MOOD':
      return {
        ...state,
        selectedMood: [...state.selectedMood, action.payload],
      };
    case 'REMOVE_MOOD':
      return {
        ...state,
        selectedMood: state.selectedMood.filter((mood) => mood !== action.payload),
      };
    case 'UPDATE_NEW_MOOD':
      return {
        ...state,
        notesData: state.notesData.map((client) => (client._id === action.payload._id ? action.payload : client)),
      };

    case 'SET_SELECTED_MOOD':
      return {
        ...state,
        selectedMood: action.payload,
      };

    case 'DELETE_MOOD':
      return {
        ...state,
        notesData: state.notesData.map((client) => {
          if (client._id === action.payload.clientId) {
            return {
              ...client,
              shiftnotes: client.shiftnotes.map((shiftNote) => {
                return {
                  ...shiftNote,
                  questions: shiftNote.questions.map((question) => {
                    return {
                      ...question,
                      noteAnsId: question.noteAnsId.filter((id) => id !== action.payload.levelId),
                      settings: question.settings.filter((setting) => setting.levelId !== action.payload.levelId),
                    };
                  }),
                };
              }),
            };
          }
          return client;
        }),
      };

    case 'FETCH_LOCATION':
      return {
        ...state,
        locationData: action.payload,
      };

    case 'TOGGLE_TASK_TIMELINE':
      return {
        ...state,
        taskData: state.taskData.map((client) => {
          if (client.clientId === action.payload.clientId) {
            return {
              ...client,
              shiftTaskList: client.shiftTaskList.map((task) => {
                if (task._id === action.payload.taskId) {
                  return {
                    ...task,
                    isComplete: !task.isComplete,
                  };
                }
                return task;
              }),
            };
          }
          return client;
        }),
      };

    default:
      return state;
  }
};

const documentReducer = (
  state = {
    documents: undefined,
  },
  action
) => {
  switch (action.type) {
    case 'FETCH_DOCUMENTS':
      return {
        ...state,
        documents: action.payload,
      };
    case 'RESET_DOCUMENTS':
      return {
        ...state,
        documents: undefined,
      };
    default:
      return state;
  }
};

/* -------------------------------------------------------------------------------------------------------- Mobile Reducers End ---------------------------------------------------------------------------- */

const store = configureStore({
  reducer: combineReducers({
    error: errorReducer,
    loading: loadingReducer,
    msg: msgReducer,
    auth: authReducer,
    client: clientReducer,
    userDoc: userDocReducer,
    staff: staffReducer,
    staffDoc: staffDocReducer,
    team: teamReducer,
    invoice: invoiceReducer,
    goal: goalReducer,
    setting: settingReducer,
    priceBook: priceBookReducer,
    payGroup: payGroupReducer,
    allowance: allowanceReducer,
    task: taskReducer,
    dashboard: dashboardReducer,
    shift: shiftReducer,
    report: reportReducer,
    companyDocument: companyDocumentReducer,
    clientShift: clientShiftReducer,
    staticPage: staticPageReducer,
    notification: notificationReducer,
    shiftDelete: shiftDeleteReducer,
    shiftDetails: shiftDetailsModalReducer,
    /* ------------------------------------------------------------------------------------------------- Mobile Reducers Start --------------------------------------------------------------------------------------- */
    mobileDashboard: mobileDashboardReducer,
    mobileNotification: mobileNotificationReducer,
    mobileShift: mobileShiftReducer,
    unavailability: unavailabilityReducer,
    progress: progressReducer,
    timeline: timelineReducer,
    clientDetails: clientDetailsReducer,
    document: documentReducer,
    /* -------------------------------------------------------------------------------------------------- Mobile Reducers End ----------------------------------------------------------------------------------- */
  }),
});

export default store;
