import {Capacitor} from '@capacitor/core';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {FirebaseMessaging} from '@capacitor-firebase/messaging';
import {LocalNotifications} from "@capacitor/local-notifications";


const addListeners = async (navigate) => {
    await FirebaseMessaging.addListener('tokenReceived', event => {
        localStorage.setItem('pushToken', event.token);
        console.log('tokenReceived', {event});
    });

    await FirebaseMessaging.addListener('notificationReceived', async event => {
        console.log('notificationReceived', {event});
        if (Capacitor.getPlatform() === 'android') {
            await showNotification(event.notification)
        }
    });

    await FirebaseMessaging.addListener('notificationActionPerformed', event => {
        console.log('notificationActionPerformed', {event});
        handleNotificationClick(event.notification, navigate, false);
    });

    await LocalNotifications.addListener("localNotificationActionPerformed", (notification) => {
        console.log("local notification action performed", notification.actionId, notification.notification);
        handleNotificationClick(notification.notification, navigate, true);
    });

    const removeAllListeners = async () => {
        await FirebaseMessaging.removeAllListeners();
    };


};
const showNotification = async (notification) => {
    await LocalNotifications.schedule({
        notifications: [
            {
                title: notification.title ?? "test local",
                body: notification.body ?? "test local",
                extra: notification.data ?? {},
                id: Math.ceil(Math.random() * 100),
                schedule: {at: new Date(Date.now() * 5000)},
                ongoing: false,
                //if ongoing:true, this notification can't be cleared
                channelId: "main",
            },
        ],
    });
};

const registerNotifications = async () => {
    let permStatus = await FirebaseMessaging.checkPermissions();

    if (permStatus.receive === 'prompt') {
        permStatus = await FirebaseMessaging.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
        console.log('User denied permissions!');
    }

};

const getDeliveredNotifications = async () => {
    const notificationList = await FirebaseMessaging.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
};

const handleNotificationClick = (notification, navigate, fromLocal) => {
    console.log('notification', notification);
    console.log('notification extra', notification.extra);
    console.log('notification extra dynamicdata', notification.extra?.dynamicData);
    console.log('notification extra dynamicdata 1', notification.data?.dynamicData);
    const data = JSON.parse(fromLocal ? notification.extra?.dynamicData : notification.data?.dynamicData);
    const shiftId = data?.id;
    console.log('shiftId', shiftId);
    const type = data?.type;
    console.log('type', type);
    switch (type) {
        case 'expense':
            navigate(`/staff/shift-details/${shiftId}/timeline/add-mileage-and-expense`);
            break;
        case 'mileage':
            navigate(`/staff/shift-details/${shiftId}/timeline/add-mileage-and-expense`);
            break;
        case 'travel_distance':
            navigate(`/staff/shift-details/${shiftId}/timeline/review-locations`);
            break;
        case 'time_entry':
            navigate(`/staff/shift-details/shift-time/${shiftId}`);
            break;
        case 'tasks':
            navigate(`/staff/shift-details/${shiftId}/timeline/shift-task`);
            break;
        case 'clientMood':
            navigate(`/staff/shift-details/${shiftId}/timeline/clients-mood`);
            break;
        case 'incident':
            navigate(`/staff/shift-details/${shiftId}/timeline/incident-or-near-miss`);
            break;
        case 'rating':
            navigate(`/staff/shift-details/${shiftId}/timeline/shift-rating`);
            break;
        case 'shift_notes':
            navigate(`/staff/shift-details/${shiftId}/timeline/add-shift-notes`);
            break;
        case 'post_it':
            const postItId = ''; //Need post it id for post it
            navigate(`/staff/add-post-it?postItId=${postItId}`);
            break;
        case 'progress_notes':
            navigate(`/staff/shift-details/${shiftId}/progress/add-progress-notes`);
            break;
        case 'feedback':
            navigate(`/staff/shift-details/${shiftId}/progress/add-feedback`);
            break;
        case 'incident_progress':
            navigate(`/staff/shift-details/${shiftId}/progress/add-incident`);
            break;
        case 'enquiry':
            navigate(`/staff/shift-details/${shiftId}/progress/add-enquiry`);
            break;
        case 'goal':
            navigate(`/staff/shift-details/${shiftId}/progress/add-goal`);
            break;
        case 'shift_info':
            navigate(`/staff/shift-details/${shiftId}`);
            break;
        default:
            break;
    }
    // window.location.pathname = '/staff/my-doc';
};

const createChannel = async () => {
    await LocalNotifications.createChannel({id: "main", name: "main", importance: 4});
};


export const PushNotificationHelper = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!Capacitor.isNativePlatform()) {
            return;
        }

        registerNotifications().then(() => {
            addListeners(navigate);
        });
        if (Capacitor.getPlatform() === 'android') {
            createChannel();
        }
    }, []);
    return <></>;
};
